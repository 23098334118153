import { render, staticRenderFns } from "./MarketMovers.vue?vue&type=template&id=58a4deea&scoped=true&"
import script from "./MarketMovers.js?vue&type=script&lang=js&"
export * from "./MarketMovers.js?vue&type=script&lang=js&"
import style0 from "./MarketMovers.vue?vue&type=style&index=0&id=58a4deea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a4deea",
  null
  
)

export default component.exports